import axios from 'axios'

const instance = axios.create({

 //baseURL: 'http://localhost:9000/'
 baseURL: 'https://deyaz-backend.systemsdynamics.ddns.net/'
})

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance
